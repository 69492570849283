.hotspotable {
    fill: rgba(255,255,255,0.01);
    stroke: rgba(255,0,0,0.5);
    stroke-width: 12;
    stroke-miterlimit: 10;
}

.inhotspotable {
    stroke-width: 2;
    stroke: limegreen;
    fill: none;
    opacity: 1;
}
